<template>
  <tr>
    <td
      v-if="hidden.id"
      @dblclick.stop="emitInput(item)"
      class="pointer">
      <fa
        v-if="selectedRow === item.id && canDestroy"
        icon="trash"
        class="text-danger pointer"
        @dblclick="destroyOrder(item.id)">
      </fa>
      {{item.id}}
      <small>
        <router-link
          v-if="item.externalDoc"
          class="text-decoration-none"
          :to="toDoc(item.externalDoc)">
          <div class="text-info">{{item.externalDoc.code}}</div>
        </router-link>
        <router-link
          v-for="child in item.children"
          :key="child.id"
          class="text-decoration-none"
          :to="toDoc(child.doc)">
          <div class="text-info">{{child.doc.code}}</div>
        </router-link>
      </small>
    </td>
    <td v-if="hidden.name">
      <div v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.approvedAt, 'text-warning': !ledger.approvedAt}">
          {{ledger.account.code}}: {{ledger.account.name}}
        </small>
      </div>
      <div v-if="item.parent">
        <router-link
          class="text-decoration-none pointer"
          :to="toDoc(item.parent.doc)">
          {{item.parent.doc.code}}
        </router-link>
      </div>
      <span
        v-else
        style="white-space: pre-line;">
        {{item.name}}
      </span>
      <small>
        <div v-if="withholdingType">
          <div class="text-info">
            {{item.withholdingTopic}}.
            {{item.withholdingName}}
            ({{item.withholdingRate}}%)
            ({{item.withholdingPrice}})
          </div>
          <div class="text-info">
            {{withholdingType.text}}
          </div>
          <div v-if="item.withholdingRemark" class="text-danger">
            หมายเหตุ: {{item.withholdingRemark}}
          </div>
        </div>
      </small>
    </td>
    <td v-if="hidden.totalPrice">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
        <br>
      </span>
      {{ item.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
import {
  DESTROY_ORDER
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    canDestroy: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      withholdingTypes: [
        {text: 'หักภาษี ณ ที่จ่าย', value: 1},
        {text: 'ออกภาษีให้ครั้งเดียว', value: 2},
        {text: 'ออกให้ตลอดไป', value: 3},
        {text: 'อื่นๆ', value: 4},
      ]
    }
  },
  computed: {
    item () {
      return this.row.item
    },
    hidden () {
      return this.row.hidden
    },
    selectedRow () {
      return this.value.find(v => v === this.item.id)
    },
    withholdingType () {
      return this.withholdingTypes.find(v => v.value === this.item.withholdingType)
    }
  },
  methods: {
    emitInput (item) {
      const found = this.value.indexOf(item.id)
      const arr = [...this.value]
      if (found === -1) {
        arr.push(item.id)
      } else {
        arr.splice(found, 1)
      }
      this.$emit('input', arr)
    },
    destroyOrder (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.emitOrderUpdated()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitOrderUpdated () {
      this.$emit('updated', null)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
