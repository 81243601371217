var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('sgv-input-radio',{staticClass:"col-12",attrs:{"options":_vm.methods,"select":"value","inline":""},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}]),model:{value:(_vm.selectedMethod),callback:function ($$v) {_vm.selectedMethod=$$v},expression:"selectedMethod"}}),(['add', 'edit'].includes(_vm.selectedMethod))?_c('div',[_c('div',{staticClass:"form-row"},[(_vm.selectedMethod === 'add')?_c('sgv-input-select',{staticClass:"col-6",attrs:{"label":"บัญชี","placeholder":"เลือกรายการ","options":_vm.options,"select":"id","validations":[
          {text: 'required!', value: _vm.$v.formData.docConfigId.$dirty && _vm.$v.formData.docConfigId.$error}
        ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,1614597918),model:{value:(_vm.formData.docConfigId),callback:function ($$v) {_vm.$set(_vm.formData, "docConfigId", $$v)},expression:"formData.docConfigId"}}):_vm._e(),(_vm.selectedMethod === 'edit')?_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"เลขที่รายการ","validations":[
          {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.orderId.$dirty && !_vm.$v.formData.orderId.minValue}
        ]},model:{value:(_vm.formData.orderId),callback:function ($$v) {_vm.$set(_vm.formData, "orderId", $$v)},expression:"formData.orderId"}}):_vm._e()],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-select',{staticClass:"col-6",attrs:{"label":"ประเภท","placeholder":"เลือกรายการ","options":_vm.topics,"select":"value","validations":[
          {text: 'required!', value: _vm.$v.formData.withholdingTopic.$dirty && _vm.$v.formData.withholdingTopic.$error}
        ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}],null,false,2460581028),model:{value:(_vm.formData.withholdingTopic),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingTopic", $$v)},expression:"formData.withholdingTopic"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"รายละเอียด","validations":[
          {text: 'required!', value: _vm.$v.formData.withholdingName.$dirty && _vm.$v.formData.withholdingName.$error}
        ]},model:{value:(_vm.formData.withholdingName),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingName", $$v)},expression:"formData.withholdingName"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-select',{staticClass:"col-6",attrs:{"label":"ผู้จ่ายเงิน","placeholder":"เลือกรายการ","options":_vm.payers,"select":"value","validations":[
          {text: 'required!', value: _vm.$v.formData.withholdingType.$dirty && _vm.$v.formData.withholdingType.$error}
        ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}],null,false,2460581028),model:{value:(_vm.formData.withholdingType),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingType", $$v)},expression:"formData.withholdingType"}}),(_vm.formData.withholdingType === 4)?_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"หมายเหตุ"},model:{value:(_vm.formData.withholdingRemark),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingRemark", $$v)},expression:"formData.withholdingRemark"}}):_vm._e()],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-currency',{staticClass:"col-6 col-sm-4",attrs:{"label":"มูลค่าบริการ","precision":2,"validations":[
          {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.withholdingPrice.$dirty && !_vm.$v.formData.withholdingPrice.minValue}
        ]},model:{value:(_vm.formData.withholdingPrice),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingPrice", $$v)},expression:"formData.withholdingPrice"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-4",attrs:{"label":"อัตราภาษี (%)","precision":2,"validations":[
          {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.withholdingRate.$dirty && !_vm.$v.formData.withholdingRate.minValue}
        ]},model:{value:(_vm.formData.withholdingRate),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingRate", $$v)},expression:"formData.withholdingRate"}}),(_vm.selectedMethod === 'add')?_c('sgv-input-currency',{staticClass:"col-6 col-sm-4",attrs:{"label":"ราคา","precision":2,"validations":[
          {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.price.$dirty && !_vm.$v.formData.price.minValue}
        ]},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}}):_vm._e()],1)]):_vm._e(),(_vm.selectedMethod ==='add')?_c('button',{staticClass:"btn btn-success",on:{"click":_vm.createOrder}},[_vm._v(" เพิ่ม ")]):_vm._e(),(_vm.selectedMethod ==='edit')?_c('button',{staticClass:"btn btn-warning mr-2",on:{"click":_vm.updateOrder}},[_vm._v(" แก้ไข ")]):_vm._e(),(_vm.selectedMethod ==='reCreate')?_c('button',{staticClass:"btn btn-info",on:{"click":_vm.reCreateDoc}},[_vm._v(" สร้างเอกสารใหม่ ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }